
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@450;500;600;700;800');


 :root{
    --mainColor: rgb(1, 111, 43);

    --transition: all 300ms ease;

    --container-width-lg: 85%;
    --container-width-md: 94%;
    --form-width: 50%;

} 

*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
}
body{
    
    font-family: "Inter", Helvetica;
    background: #FFFFFF;
    line-height: 1.6;
    font-style: normal;
}
a{
    text-decoration: none;
    color: var(--mainColor);
}
section{
    margin-top: 6rem;
}
.imgCircleDiv {
    width: 120px;
    height: 120px;
    border-radius: 90%;
    background-color: #ddd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.container{
    width: var(--container-width-lg) !important;
    margin-inline: auto;
}
.alignItems,.align_items{
    display: flex !important;
    align-items: center !important;
    gap: 2rem;
}
ul{
    list-style: none;
    padding-left: 0 !important;
}
img {
    display: block;
    object-fit: cover;
    width: 100%;
    border-radius: 8px;
}

h1{
    font-weight: 550;
    font-size: 83px !important;
    line-height: 1.1 !important;
}
h2{
    /* font-weight: 550; */
    font-size: 50px;
    line-height: 1.29 !important;
}
h3{
    /* font-weight: 540; */
    font-size: 25px; 
    line-height: 1.2 !important;
    font-weight: 550;
}
h4{
    /* font-weight: 540; */
    font-size: 25px; 
}



/*===================== NAVBAR =====================*/
nav{
    width: 89vw;
    height: 5rem;
    display: grid;
    place-items: center;
    background: transparent;
    margin-bottom: 1rem;
    margin-inline: auto;
    z-index: 10;
}
.nav_container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: 1rem;
}
.navbar {

    --bs-navbar-toggler-border-color: none !important;
    --bs-navbar-toggler-border-radius: none !important;
    --bs-navbar-toggler-focus-width: none !important;
}
.nav_logo1{
    display: none;
}
.nav_logo  {
    width: 6rem !important;
    display: block;
    margin: 0 1rem;
}
.nav_menu, .mx-auto{ 
    display: flex;
    align-items: center;
    gap: 7rem;
    white-space: nowrap;
    z-index: 10;
}
.nav_menu a{
    color: black;
}
.nav_toggle-btn{
    display: none;
}


/*=================================================== FOOTER ==================================================*/
.footer_wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 7rem;
    margin-bottom: 0 !important;

}
.gapfooter{
    margin-top: 19rem !important;
    margin-bottom: 0 !important;
}
.footer{
    width: 100%;
    background-color: black;
    display: flex;
    flex-direction: row;
    color: #FFFFFF;
}
.footer_detail{
    width: 95%;
    display: flex;
    gap: 4rem;
    padding-top: 6rem;
    padding-left: 4rem;
    padding-bottom: 6rem;
    padding-right: 0.5rem;
    margin-inline: auto;
}
.footer_detail-text{
    width: 33%;
}
.footer_detail-text-tel{
    width: 23%;
}
.footer_text-detail h2, .footer_detail-text-tel h2{
    font-size: 19px;
}
.footer_detail-text p, .footer_detail-text-tel p{
    font-size: 13px;
    font-weight: 0;
    color: #DAD9D9;
}
.footer_img{
    width: 40%;
}
.footer_img-carrier{
    width: 100%;
}
.footer_info{
    margin-top: -0.89rem;
}
.footerImg{
    width: 98%;
    margin-top: -25.17rem !important;
}
.copyright{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.6rem;
    font-weight: 500;
    font-size: 15px;
}
.flex_icon_carrier{
    width: 43%;
}
.flex_icon{
    width: 100%;
    display: flex;
    gap: 2rem;
    align-items: center;
    margin-top: 1rem;
}
.flex_iconCarrier{
    width: 7%;
    cursor: pointer;
}
.flex_iconCarrier1{
    width: 6%;
    cursor: pointer;
}

/* ============================================== ABOUT US ==============================================*/

.about_first-section{
    margin-top: 4rem;
    width: 100%;
}
.about_firstImg{
    width: 92.5% !important;
}
.FirtstImg{
    width: 100%;
    border-radius: 2px;
    height: 460px ;
}
.about_text-header{
    margin-top: 5.6rem;
}
.about_text-header p{
    font-size: 18px !important;
    margin-bottom: 0 !important;
    color: #676666;
}
.about_text-aboutImg {
    display: flex;
    align-items: center;
    gap: 10rem !important;
    margin-top: 2.2rem;
}
.service_text-aboutImg{
    display: flex;
    align-items: center;
    /* gap: 35rem !important; */
    margin-top: 2.2rem;
    justify-content: space-between;
}
.service_vector{
    margin-top: 0rem;
    width: 43%;
    height: 1px;
}
.about_text-aboutImg p, .service_text-aboutImg p{
    font-weight: 660;
    font-size: 16px;
}
.vector{
    margin-top: -1rem;
    width: 68%;
    height: 1px;
}
.valueOffer{
    /* display: flex;
    align-items: center;
    justify-content: center !important;
    text-align: center !important; */
    margin-top: 27rem;
    margin-bottom: 4rem;
}
.perfect_product{
    display: flex;
    align-items: center;
    justify-content: center !important;
    text-align: center !important;
}
.about_valueOffer{
    display: flex;
    margin-top: 5rem;
}
.aboutus_container, .aboutus1_container{
    width: 100%;
    display: flex;
    gap: 4rem;
    margin-top: 0.5rem;
    margin-bottom: 5rem !important;
}
.whyChoose{
    font-size: 45px;
    font-weight: 550;
    line-height: 70px;
}
.img_positioning{
    width: 100%;
}
.commitmentCarrier, .expertizeCarrier{
    width: 50%;
}
.commitmentTextCarrier{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-top:2%;
}
.commitmentTextCarrier p, .expertizeTextCarrier p{
   color: #5A5A5A;
    font-size: 18px;
}
.commitmentImg1{
    width: 33%;
}
.commitmentImgCarrier{
    margin-top: 2rem;
    width: 100%;
}
.CommitmentImg, .CommitmentImgOne{
    width: 100%;
    border-radius: 8px;
    height: 300px !important;
    object-fit: cover !important;
}
.aboutus_partner{
    width: 55%;
}


/* ============================================== HOME ============================================== */

.home_section{
    margin-top: 7rem;
}
.home_class{
    margin-bottom: -9rem !important;
}
.para1{
    margin-bottom: -0.2rem;
}
.home_navWrapper{
    background-image: url("./Images/Screenshot\ 2024-08-09\ at\ 01.44.38\ 1.png");
    background-repeat: no-repeat;
    background-size: cover !important;
    width: 100%;
    height: 90vh !important;
}
.home_trusted-partner h1{
    text-align: center;
}
.home_trusted-partner p{
    text-align: center;
    color: #5A5A5A;
    font-size: 20px;
}
.home_flex-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 2rem;
}
.card_flex-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 3.5rem;
}
.Btn_flex-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.property_section{
    margin-top: 2.5rem;
    margin-bottom: 5rem;
}
.viewalllinkdiv{
    margin-top: 0.9rem;
    display: flex;
    justify-content: center;
}
.viewall{
    padding: 0.6rem 5rem;
    background-color: black;
    color: #FFFFFF !important;
    border-radius: 8px;
    text-align: center;
}
.primeText{
    font-size: 25px;
    font-weight: 550;
}
.cardBody{
    color: #676666;
    font-size: 18px;
}
.Img_height{
    height: 490px;
}
.cardCarrier{
    width: 50%;
}
.naira{
    font-size: 39px;
    font-weight: 550;
}

.home-buy_container, .home-sell_container, .home-invest_container{
    width: 32%;
    align-items: center;
}
.home_buy-text, .home_sell-text, .home_invest-text{
    padding-top: 3rem;
    padding-left: .6rem;
    padding-bottom: 0.1rem;
    padding-right: 1rem;
    color: #FFFFFF;
}

.home_buy-text p, .home_sell-text p, .home_invest-text p{
    font-size: 16px !important;
}
.home_buy{
    background-image: url("./Images/Mask\ group\ \(9\).png");
    padding-top: 18rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50/50;
    height: 460px !important;
}
.home_sell {
    background-image: url("./Images/Mask\ group\ \(82\).png");
    padding-top: 18rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50/50;
    height: 460px !important;
}
.home_invest{
    background-image: url("./Images/box.png");
    padding-top: 18rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50/50;
    height: 460px !important;
}
.home_section-four{
    margin-top: 7rem;
}
.our_personalised-approch{
    width: 60%;
    display: flex;
    align-items: center;
    padding: 4rem;
}
.our_personalised-approch-text{
    text-align: justify;
    font-size: 36px;
    color: black;
}
.our_personalised-approch-img{
    width: 40%;
}
.secondImg{
    height: 580px;
}
.simplifyCarrier{
    /* margin-top: -25rem; */
    z-index: -10;
}
.simplify{
    background: black;
    padding: 10rem 4rem 10rem 4rem;
    color: #FFFFFF;
}
.popular{
    padding: 0 4rem;
    width: 100% !important;
}
.view_all{
    color: black;
    font-size: 16px;
    font-weight: 550;
    display: flex;
    gap: 1rem;
}
.fafa{
    margin-top: 0.3rem;
}
.popular_text{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.popular_header h2{
    font-weight: 550;
}
.Image_position_carrier{
    width: 50%;
    /* margin-top: 3rem; */
}
.imgposition,.imgpositionfirst{
    width: 100%;
    border-radius: 10px;
    /* height: 370px; */
    margin-left: 0%;
}
.imgpositionfirst{
    height: 300px !important;
}
.positionleft{
    width: 100%;
    border-radius: 10px;
    height: 370px;
}
.simplify_title{
    text-align: center;
    margin-bottom: 2.5rem;
}
/* .weText{
    font-size: 35px;
    font-weight: 500;
} */
.btn_flex{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    margin: 2rem 0;
}
.yellow_btn{
    background-color: var(--mainColor);
    border-radius: 10px;
    color: #FFFFFF !important;
    padding: .7rem 4rem;
    cursor: pointer;
}
.white_btn{
    border: 1px solid var(--mainColor);
    border-radius: 10px;
    padding: .7rem 4rem;
    cursor: pointer;
    color: var(--mainColor) !important;
}
.yellow_btn a{
    color: #FFFFFF;
}
.white_btn a{
    color: #FEC650 !important;
}
.choose_property-text p{
    color: #DAD9D9;
}



/*=================================================== ERROR PAGE ==================================================*/
.error-center{
    text-align: center;
    padding-top: 5rem;
    margin-inline: center;
    display: block;
    width: 100%;
}
.error-page h2{
    margin-top: 3rem ;
    font-weight: 700;
}

.btn{
    display: inline-block;
    width: fit-content;
    padding: 0.5rem 1rem !important;
    border-radius: var(--radius-2);
    background: var(--color-white);
    font-weight: 500;
    cursor: pointer;
    transition: var(--transition);
}
.btn.primary{
    background: var(--mainColor);
    color: var(--color-white);
}





/*=================================================== SERVICES ==================================================*/

.serviceSectionOne{
    width: 100%;
}
.service_container{
    width:  100%;
    display: flex;
    flex-direction: row;
}
.home_firstImgCarrier{
    width:100%;
    margin-top: 2.7rem;
}
.property_investment{
    width: 60%;
    padding-top: 5rem;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.property_img{
    width: 40% !important;
    margin-top: -7rem;
    z-index: -5;
}
.service_text-serviceImg {
    display: flex;
    align-items: center;
    gap: 4rem !important;
    margin-top: 2rem;
}
.service_text-serviceImg p{
    font-weight: 550;
    font-size: 14px;
}
.serviceVector{
    margin-top: -1rem;
    width: 50%;
    height: 0.5px;
}
.propertyAvatar{
    height: 400px;
    object-fit: cover;
}
.dependable_point{
    width: 60%;
    display: flex;
    align-items: center;
}
.dependable_img{
    width: 40%;
}
.services_sell{
    width: 70%;
    margin-top: 1.5rem;
}
.services_sell p{
    font-size: 19px;
    color: #676666;
}
.talkToUs_link{
    margin-top: 0.5rem;
}
.talk_to_us{
    text-decoration: underline !important;
    color: black;
    font-size: 19px;
    font-weight: 650;
    padding-top: 2rem !important;
}
.services_sell_img{
    margin-top: 1rem;
}
.service_sellImg{
    height: 400px;
}
.downArrowIcon{
    width: 7%;
    margin-top: 1rem;
}
.investment_Text{
    padding-top: 1.5rem;
    width: 93%;
    margin-top: 1rem;
}
.investment_Text{
    font-size: 18px;
}
.services_Consultancy{
    width: 90%;
}
.advisory{
    margin-bottom: 1.5rem;
}
.faq_Text{
    margin-top: 1.5rem;
}
.propertyText{
    width: 60% !important;
}
.propertyText_carrier{
    padding-top: 7rem;
    display: flex;
    align-items: center !important;
    justify-content: center;
}
.downArrow{
    width: 30%;
    height: 120px;
}



/*=================================================== SERVICES ==================================================*/
.property_container{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 3rem;
    margin-top: 6rem;
}
.estate, .estate1{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.estate_img{
    width: 100%;
}
.estate_detail p{
    margin-bottom: 0 !important;
    font-size: 18px !important;
    color: #676666;
}
.PropertyAvatar{
    height: 400px;
    object-fit: cover;
}
.property_details{
    color: black !important;
    display: flex;
    flex-direction: column;
    gap: 1.5rem
}
.estate_wrapper{
    margin-bottom: 5rem;
}
.avatarCarrier{
    width: 100%;
}
.avatar{
    height: 450px;
    object-fit: cover !important;
}
.estate_title, .estate_detail{
    width: 100%;
    margin-top: 1rem;
}
.estate_title_header{
    display: flex;
    justify-content: space-between;
    color: black;
}
.estate_title p{
    color: #676666;
    font-size: 12px;
}
.amount{
    color: #676666;
}

/*=================================================== PROPERTY-DETAIL ==================================================*/
.propertyText{
    width: 30%;
}
.propertyDetail_img{
    width: 70%;
    margin-top: -6rem;
    z-index: -5;
}
.PropertyDetailAvatar{
    height: 450px;
    object-fit: cover;
}
.properyDetail_container{
    display: flex;
    width: 95%;
    gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 4.5rem;
}
.propertyImg{
    width: 30.5%;
}
.propertyDetail_text{
    width: 100%;
    margin-top: 1rem !important;
}
.propertyDetail_text p{
    font-size: 18px !important;
    color: #676666;
    margin-bottom: -0.1rem;
}
.propertyDetail_text h5{
    font-weight: 550;
}
.Details{
    width: 10%;
    padding: 0.4rem 1.5rem;
    background-color: black !important;
    color: #FFFFFF !important;
    border-radius: 10px;
    text-wrap: nowrap;
}
.estate_detail{
    margin-left: 0rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
}
.estate_detail_pay{
    margin-left: 0rem;
    display: flex;
    flex-direction: column;
    align-items: initial;
    gap: 0.5rem;
    flex-wrap: wrap; 
}
.estate_pargraph{
    font-size: 18px;
    margin-top: 0rem !important;
    margin-right: 0.4rem;
    display: flex;
    gap: 0.3rem;
    align-items: center !important;
}
.estate_pargraph p{
    color: #5A5A5A;
}
.mdCircle{
    font-size: 10px;
    color: var(--mainColor);
}
/* .contact_us_flex{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 3rem;
} */
.contactUs_img_carrier{
    width: 45%;
}
.contact_img{
    height: 700px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.contactUs_form_carrier{
    width: 60%;
    margin-inline: auto;
}
.contactUs_form{
    padding: 5rem;
}.btnDiv{
    width: 100%;
    display: flex;
    justify-content: center;
}
.btn-primary{
    width: 60% !important;
    border: none;
    border-radius: 50px;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--mainColor);
}
.btn-primary:hover{
    background-color: var(--mainColor) !important;
}
.form-control{
    color: rgba(0, 0, 0, .87);
    border-color: rgba((0), 0, 0, .42);
    box-shadow: none !important;
    border-radius: 4px;
    background-color: white !important;
    padding: 0.7rem  0.5rem;
}
.removeBg{
    background-color: #FFFFFF !important;
}
.inTouch, .contactUs_hear_From_you h3{
    font-weight: 550;
}
.inTouch, .contactUs_form p{
    text-align: center;
}
.contactUs_reactOut{
    width: 20%;
}
.react_out{

    border: 1px solid;
    border-color: #FEC650;
    padding: 0.5rem 2rem;
    border-radius: 25px;
    font-size: 14px;
    color: #5A5A5A;
}
.peace_img{
    width: 40%;
}
.peace_Img_carrier{
    width: 100%;
}
.peace_text{
    width: 50%;
    display: flex;
    align-items: center;
}
.peace_text_detail h2{
    font-weight: 540;
}
.peace_text_detail p{
    text-align: initial !important;
    color: #5A5A5A;
    font-size: 20px;
}
.springFlex{
    display: flex;
    margin-top: 1.5rem;
    width: 100%;
    gap: 2.5rem;
}
.springBanner{
    margin-top: 2rem;
}
.springBanner img{
    width: 92.5%;
    height: 350px;
    object-fit: cover;
}
.springText{
    width: 50%;
    padding: 1rem;
}
.spring_text_carrier p, .keyfeatures p{
    line-height: 1.3;
    margin-bottom: 0.4rem;
    color: #5A5A5A;
    
}
.keyfeatures{
    width: 33%;
}
.keypay{
    font-weight: 600;
    margin-top: 1.5rem;
    text-align: center;
}
.keypays{
    font-weight: 600;
    margin-top: 1.5rem;
}
.Sview_text{
    margin-top: 2.3rem;
}
.S_view_estate{
    font-weight: 600;
    text-align: center;
    margin-bottom: 1.5rem;
}
.dotCircle{
    display: flex;
    gap: 1.5rem;
    /* text-align: center;
    justify-content: center; */
}
.keyIcon{
    width: 2rem;
    height: 1rem;
    background-color: var(--mainColor);
    border-radius: 5rem;
}
.estateCarrier{
    margin-top: 1rem;
}
.estateCarrier h5{
    font-weight: 550;
}












































/*=================================================== MEDIA QUERY(small device) ==================================================*/


@media screen and (max-width:1030px) {
    body{
        font-size: 0.9rem;
    }
    .container{
        width: var(--container-width-lg) !important;
    }

    section{
        margin-top: 2rem;
    }

    h1{
        font-size: 50px !important;
    }
    h2{
        font-size: 35px !important;
    }
    h3{
        font-weight: 540;
        font-size: 22px; 
    }
    h4{
        font-weight: 540;
        font-size: 20px; 
    }
   

nav{
    width: 90vw;
    height: 5rem;
    display: grid;
    place-items: center;
    background: transparent;
    margin-bottom: 1rem;
    margin-inline: auto;
    z-index: 10;
}
.nav_container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.nav_logo1{
    display: none;
}
.nav_logo  {
    width: 10rem;
    display: block;
    margin: 0 1rem;
}
.nav_menu{ 
    display: flex;
    align-items: center;
    gap: 4rem;
    white-space: nowrap;
}

.home_navWrapper{
    background-image: url("./Images/Screenshot\ 2024-08-09\ at\ 01.44.38\ 1.png");
    background-repeat: no-repeat;
    background-size: cover !important;
    width: 100%;
    height: 35vh !important;
}
.nav_menu a{
    color: black;
}
.nav_toggle-btn{
    display: none;
}

.our_personalised-approch-text{
    padding: 4rem 0rem 0 0;
    display: flex;
    align-items: center;
}
.FirtstImg{
    height: 300px !important;
}


    .footer{
        display: flex;
        padding: 0;
    }
    .footer_detail{
        width: 95%;
        gap: 1.5rem;
        padding-top: 2rem;
        padding-left: 4rem;
        padding-bottom: 1rem;
        padding-right: 0.2rem;

   }

   .footer_text-detail h2{
    font-size: 13px;
   }
   .footer_img{
    width: 35%;
   }
   .footer_img-carrier{
    width: 100%;
   }
   .footerImg{
     margin-top: -16.9rem !important;
   }
   .gapfooter{
    margin-top: 9rem !important;
   }

   .aboutus_container{
    display: flex;
   }
   .aboutus1_container{
    display: flex;
    flex-direction: column-reverse;
   }
   .commitmentImg, .commitmentCarrier, .expertizeCarrier{
    width: 100%;
   }
   .expertizeCarrier{
    flex-direction: column-reverse !important;
   }
   .CommitmentImg, .CommitmentImgOne{
    height: 200px !important;
   }
   .serviceVector {
    margin-top: -0.5rem;
    width: 50%;
    height: 1px;
}
.valueOffer {
    font-weight: 550;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center !important;
    text-align: center !important;
    margin-top: 24rem;
    margin-bottom: 3rem;
}
.home_buy{
    background-image: url("./Images/Mask\ group\ \(9\).png");
    padding-top: 17.5rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50/50;
    height: 400px;
}
.home_sell {
    background-image: url("./Images/Mask\ group\ \(82\).png");
    padding-top: 17.5rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50/50;
    height: 400px;
}
.home_invest{
    background-image: url("./Images/box.png");
    padding-top: 17.5rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50/50;
    height: 400px;
}
.home_buy-text p, .home_sell-text p, .home_invest-text p{
    font-size: 17px;
}
.choose_property-text p {
    color: #DAD9D9;
    font-size: 12px;
}
.weText {
    font-size: 35px;
    font-weight: 500;
}
.choose_property{
    font-size: 16px;
    font-weight: 500;
}
.avatar{
    height: 400px;
}
.imgposition{
    width: 100%;
    border-radius: 0;
    height: 380px;
    margin-left: 0rem;
    border-radius: 8px;
}

}



@media screen and (max-width: 920px){
    .footerImg{
        margin-top: -13.3rem !important;
      }
      .contactUs_reactOut{
        width: 30%;
        text-align: center;
    }
    .footer_detail{
        width: 95%;
        gap: 1.5rem;
        padding-top: 1rem;
        padding-left: 1rem;
        padding-bottom: 1rem;
        padding-right: 0.2rem;

   }
}

@media screen and (max-width: 900px) {
    h1{
        font-size: 45px !important;
    }
    h2{
        font-size: 30px !important;
    }
    h3{
        font-weight: 540;
        font-size: 18px; 
    }
    h4{
        font-weight: 540;
        font-size: 20px; 
    }
    nav{
        width: 90vw;
        height: 5rem;
        display: grid;
        place-items: center;
        background: transparent;
        margin-bottom: 1rem;
        margin-inline: auto;
        z-index: 10;
    }
    .nav_logo  {
        width: 8rem;
        display: block;
        margin: 0 0.4rem;
    }
    .nav_menu{ 
        display: flex;
        align-items: center;
        gap: 3rem;
        white-space: nowrap;
    }
    .gapfooter{
        margin-top: 5rem !important;
       }
    .footerImg{
        margin-top: -10.8rem !important;
      }
      .footer_detail{
        width: 95%;
        gap: 1.5rem;
        padding-top: 1rem;
        padding-left: 1rem;
        padding-bottom: 1rem;
        padding-right: 0.2rem;

   }
   .flex_icon{
    justify-content: center;
   }
   .Img_height{
    height: 300px;
}

    .home_navWrapper{
        background-image: url("./Images/Screenshot\ 2024-08-09\ at\ 01.44.38\ 1.png");
        background-repeat: no-repeat;
        background-size: cover !important;
        width: 100%;
        height: 53vh !important;
    }
    .FirtstImg {
        height: 250px;
    }
    .home_buy{
        background-image: url("./Images/Mask\ group\ \(9\).png");
        padding-top: 15.5rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50/50;
        height: 350px;
    }
    .home_sell {
        background-image: url("./Images/Mask\ group\ \(82\).png");
        padding-top: 15.5rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50/50;
        height: 350px;
    }
    .home_invest{
        background-image: url("./Images/box.png");
        padding-top: 15.5rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50/50;
        height: 350px;
    }
    .home_buy-text p, .home_sell-text p, .home_invest-text p{
        font-size: 15px;
    }
    .home_buy-text, .home_sell-text, .home_invest-text {
        padding-top: 5rem;
        padding-left: .6rem;
        padding-bottom: 0.1rem;
        padding-right: .5rem;
        color: #FFFFFF;
    }
    .valueOffer {
        font-weight: 550;
        font-size: 25px;
        display: flex;
        align-items: center;
        justify-content: center !important;
        text-align: center !important;
        margin-top: 10srem;
        margin-bottom: 5rem;
    }


}

 @media screen and (max-width: 769px) {
    .home_navWrapper{
        background-image: url("./Images/Screenshot\ 2024-08-09\ at\ 01.44.38\ 1.png");
        background-repeat: no-repeat;
        background-size: cover !important;
        width: 100%;
        height: 62vh !important;
    }
    .footerImg{
        margin-top: -12rem !important;
        height: 350px !important;
      }

        .whyChoose{
            font-size: 25px;
            line-height: 30px;
        }

} 

@media screen and (max-width:600px) {
    body{
        font-size: 0.75rem;
    }
    .container{
        width: var(--container-width-md);
    }

    section{
        margin-top: 2rem;
    }
    h1{
        font-size: 25px;
    }
    .service_vector{
        margin-top: -0.7rem !important;
    }
    .Image_position_carrier{
        width: 100%;
        /* margin-top: 3rem; */
    }
    .alignItems{
        display: flex !important;
        flex-direction: column-reverse;
        align-items: center !important;
        gap: 1rem;
    }
    .align_items{
        display: flex !important;
        flex-direction: column;
        align-items: center !important;
        gap: 2rem;
    }
    .AtGrejahmic{
        display: none;
    }
    .footer_detail{
        width: 95%;
        gap: 1.5rem;
        padding-top: 1rem;
        padding-left: 1rem;
        padding-bottom: 1rem;
        padding-right: 0.2rem;

   }
    
    /* NAVBAR */    

    .nav_container{
        position: relative;
        display: flex;
        justify-content: space-between;
        background-color: #FFFFFF;
    }
    .nav_menu {
        position: absolute;
        top: 5rem;
        right: 0;
        flex-direction: column;
        gap: 0;
        width: 80vw;
        background-color: #FFFFFF;
    }
    .nav_menu li{
        box-shadow: -2rem 4rem 4rem rgba(0, 0, 0, 0.026);
        animation: dropDown 500ms ease forwards;
        opacity: 0;
        transform: rotateX(90deg);
        background-color: #FFFFFF;
        transform-origin: top;
    }
    @keyframes dropDown {
        to{
            opacity: 1;
            transform: rotateX(0);
            background-color: #FFFFFF !important;
        }
    }
    .nav_menu li:nth-child(2) {
        animation-delay: 300ms;
        background-color: #FFFFFF;
        padding: 0 5rem;
    }
    .nav_menu li:nth-child(3) {
        animation-delay: 600ms;
        background-color: #FFFFFF;
        padding: 0 5.1rem;
    }
    .nav_menu li:nth-child(4) {
        animation-delay: 900ms;
        background-color: #FFFFFF;
        padding: 0 5.4rem;
    }
    .nav_menu li:nth-child(5) {
        animation-delay: 1200ms;
        background-color: #FFFFFF;
        padding: 0 5rem;
    }

    .nav_menu li a{
        display: block;
        background: var(--color-white);
        padding: 0.85rem 2rem;
    }
    .nav_toggle-btn{
        display: inline-block;
        background: transparent;
        font-size: 1.4rem;
        cursor: pointer;
    }

    .nav_logo1{
        display: block;
        width: 3.5rem;
    }
    .nav_logo  {
        display: none !important;
    }
    /*==================== FOOTER ====================*/
    .footer{
        display: flex;
        padding: 0;
    }

   .footer_text{
    width: 50%;
   }
   .footer_text-detail h2{
    font-size: 16px;
   }
   .footer_img{
    width: 35%;
   }
   .footer_img-carrier{
    width: 100%;
   }
   .footerImg{
     margin-top: -10rem;
   }
   .gapfooter{
    margin-top: 0.5rem !important;
   }
   .flex_icon_carrier h5, .footer_detail-text-tel,.footer_detail-text{
    font-size: 19px !important;
   }

   .aboutus_container{
    display: flex;
    flex-direction: column;
   }
   .commitmentImg, .commitmentCarrier, .expertizeCarrier{
    width: 100%;
   }
   .expertizeCarrier{
    flex-direction: column-reverse !important;
   }

.contactUs_reactOut{
  width: 55%;
  text-align: center;
}
.contact_us_flex{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
}
.contactUs_img_carrier{
    width: 100%;
}
.contact_img{
    height: 200px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.contactUs_form_carrier{
    width: 100%;
}
.contactUs_form{
    padding: 2rem;
}
.react_out{
    padding: 0.3rem 2rem;
    border-radius: 20px;
    font-size: 12px;
}
   .home_flex-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.home-buy_container, .home-sell_container, .home-invest_container{
    width: 100%;
    align-items: center;
}
.dependable_point {
    width: 100%;
    display: flex;
    align-items: center;
}
.dependable_img {
    width: 100%;
}
.dependableImg{
    height: 200px;
}
.home_buy{
    background-image: url("./Images/Mask\ group\ \(9\)\ -\ media.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50/50;
    height: 250px !important;
}
.home_sell{
    background-image: url("./Images/Mask\ group\ \(82\)\ -\ media.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50/50;
    height: 250px !important;
}
 .home_invest{
    background-image: url("./Images/media.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50/50;
    height: 250px !important;
}
.home_buy-text p, .home_sell-text p, .home_invest-text p{
    margin-top: -1rem;
    line-height: 1 !important;
}
.home_buy-text h4, .home_sell-text h4, .home_invest-text h4{
    margin-top: -10rem;
    margin-bottom: 1.2rem;
}
.our_personalised-approch-text {
    padding: 0rem 0rem 0 0;
    display: flex;
    /* align-items: center; */
}
.home_section-four{
    margin-top: 2.5rem;
}
.our_personalised-approch{
    width: 100%;
}

.our_personalised-approch-img{
    width: 100%;
}
.secondImg{
    height: 200px;
}
.FirtstImg{
    height: 200px;

}
.CommitmentImg{
    height: 200px;
}
.vector{
    margin-top: -1rem;
    width: 58%;
    height: 1px;
}
.commitmentCarrier {
    padding: 0rem 1rem 0 0;
}
/* .marginSide{
    paddingTop: 
} */
.aboutus_container, .aboutus1_container{
    margin-top: 0rem;
    margin-bottom: 0rem !important;
}
.aboutus_partner{
    text-align: center;
    width: 100%;
}
.peace_img{
    width: 100%;
}
.peace_text{
    width: 100%;
}
.cardCarrier{
    width: 100%;
}
.card_flex-container{
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    gap: 3.5rem;
}
.popular{
    padding: 2rem 2rem;
}
.popular_text{
    display: flex;
    flex-direction: row;
    align-items: normal;
    justify-content: space-between;
}
.view_all{
    color: black;
    font-size: 16px;
    font-weight: 500;
    margin-top: 0rem !important;
}
.popular_header h2{
    font-weight: 500;
}
.imgposition{
    width: 100%;
    border-radius: 0;
    height: 200px;
    margin-left: 0rem;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.positionleft{
    width: 100%;
    border-radius: 0;
    height: 200px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.Img_height{
    height: 200px;
}
.Details{
    width: 38%;
}
.name_carrier{
    width: 50%;
}
.amt_carrier{
    width: 50%;
    display: flex;
    justify-content: end;
}
.primeText{
    font-size: 20px;
    font-weight: 550;
}
.naira{
    font-size: 19px;
    font-weight: 550;
}
.Btn_flex-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: normal;
}
.cardBody{
    text-wrap: nowrap;
}



































}

@media screen and (max-width: 550px) {
    h1 {
        font-size: 39.42px !important;
    }
    h2 {
        font-size: 26px !important;
    }
    .weText {
        font-size: 26px;
        font-weight: 500;
    }
    .home_navWrapper{
        background-image: none;
        background-repeat: no-repeat;
        background-size: cover !important;
        width: 100%;
        height: 92vh !important;
    }
    .home_buy{
        background-image: url("./Images/Mask\ group\ \(9\)\ -\ media.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50/50;
        height: 250px !important;
    }
    .home_sell{
        background-image: url("./Images/Mask\ group\ \(82\)\ -\ media.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50/50;
        height: 250px !important;
    }
     .home_invest{
        background-image: url("./Images/media.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50/50;
        height: 250px !important;
    }
    .home_buy-text p, .home_sell-text p, .home_invest-text p{
        margin-top: -1rem;
    }
    .home_buy-text h4, .home_sell-text h4, .home_invest-text h4{
        margin-top: -10rem;
    }
}

@media screen and (max-width: 450px) {
    h1 {
        font-size: 25px !important;
    }
    h2 {
        font-size: 21px !important;
    }
    h4{
        font-size: 17px;
    }
    h5{
        font-size: 15px !important;
    }
    p{
        font-size: 12px !important;
    }
    .flex_icon_carrier h5, .footer_detail-text-tel, .footer_detail-text {
        font-size: 15px !important;
    }

    .home_trusted-partner p {
        text-align: center;
        font-size: 14px;
    }
    .FirtstImg {
        height: 160px !important;
    }
    .home_navWrapper{
        background-image: none;
        background-repeat: no-repeat;
        background-size: cover !important;
        width: 100%;
        height: 63vh !important;
    }
    .valueOffer {
        margin-top: -2rem;
        margin-bottom: 2rem;
    }
    .simplify {
        background: black;
        padding: 3rem 1.3rem;
        color: #FFFFFF;
    }
    .weText {
        font-size: 26px;
        font-weight: 500;
    }
    .home_flex-container {
        gap: .2rem;
    }
    .simplifyCarrier{
        margin-top: 2rem;
    }
    .simplify_title{
        text-align: center;
        margin-bottom: 2rem;
    }
    .home-buy_container, .home-sell_container{
        margin-bottom: 1rem;
    }
    .home_buy{
        background-image: url("./Images/Mask\ group\ \(9\)\ -\ media.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50/50;
        height: 250px !important;
    }
    .home_sell{
        background-image: url("./Images/Mask\ group\ \(82\)\ -\ media.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50/50;
        height: 250px !important;
    }
     .home_invest{
        background-image: url("./Images/media.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50/50;
        height: 250px !important;
    }
    .home_buy-text p, .home_sell-text p, .home_invest-text p{
        margin-top: -1rem;
        font-size: 14px !important;
    }
    .home_buy-text h4, .home_sell-text h4, .home_invest-text h4{
        margin-top: -10rem;
    }
    .home_flex-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: .1rem;
    }
    .footer_wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 3rem;
        margin-bottom: 0 !important;
    }
    .btn_flex {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: center !important;
        margin-inline: auto !important;
        gap: 2rem;
        margin: 2rem 0;
    }
    .white_btn {
        border: 1px solid var(--mainColor);
        border-radius: 10px;
        padding: .5rem 2.3rem;
        text-wrap: nowrap;
        cursor: pointer;
    }
    .yellow_btn {
        background-color: var(--mainColor);
        border-radius: 10px;
        text-wrap: nowrap;
        color: #FFFFFF !important;
        padding: .5rem 2.3rem;
        cursor: pointer;
    }
    .springBanner img{
        height: 170px;
    }
    .whyChoose {
        line-height: 25px;
        font-size: 20px !important;
        margin-bottom: -1rem;
    }
    .commitmentTextCarrier {
        padding-top: 0%;
    }
    .gapfooter{
        margin-top: 1.5rem !important;
        margin-bottom: 0 !important;
    }
    .footer{
        width: 100%;
        background-color: black;
        display: flex;
        flex-direction: column;
        color: #FFFFFF;
    }
    .footer_detail{
        width: 100% !important;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 2rem !important;
    }
    .footer_detail-text, .footer_detail-text-tel,.flex_icon_carrier{
        width: 100% !important;
    }
    .footer_text-detail h2{
        font-size: 19px;
    }
    .footer_text-detail p{
        font-size: 10px;
        font-weight: 0;
        color: #DAD9D9;
    }
    .footer_img{
        width: 100%;
    }
    .footer_img-carrier{
        width: 100%;
    }
    .footer_info{
        margin-top: -0.7rem;
    }
    .footerImg{
        width: 60%;
        display: none;
    }
    .footerImg{
        width: 100%;
        height: 100px;
    }
    .copyright{
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.6rem;
        font-weight: 500;
        font-size: 13px;
    }
    .flex_icon{
        width: 100%;
        display: flex;
        gap: 2rem;
        align-items: center;
    }
    .flex_iconCarrier{
        width: 6%;
        cursor: pointer;
    }
    .flex_iconCarrier1{
        width: 5%;
        cursor: pointer;
    }
    .home_section{
        margin-top: 2rem;
    }
    .about_text-header p{
        font-size: 14px !important;
        margin-bottom: 0 !important;
        color: #676666;
    }
    .about_text-aboutImg {
        display: flex;
        align-items: center;
        gap: 4.7rem !important;
        margin-top: 2.2rem;
    }
    .about_text-aboutImg p, .service_text-aboutImg p{
        font-weight: 660;
        font-size: 14px;
    }
    .about_first-section{
        margin-top: 2rem;
        width: 100%;
    }
    .about_valueOffer{
        margin-top: 2rem;
    }
    .commitmentTextCarrier p, .expertizeTextCarrier p{
        color: #5A5A5A;
         font-size: 14px;
     }
     .commitmentImg, .commitmentImg1{
        width: 100%;
    }
    .commitmentImgCarrier{
        margin-top: 2rem;
        width: 100%;
    }
    .CommitmentImgOne{
        width: 100%;
        border-radius: 8px;
        height: 200px;
    }
    .CommitmentImg{
        display: none !important;
        height: 0;
    }
    .about_firstImg{
        width: 92.5% !important;
    }
    .commitmentImg1{
        margin-bottom: -3.5rem;
    }
    .about_text-header{
        margin-top: 2rem;
    }
    .service_sellImg, .avatar{
        height: 170px;
    }
    .services_sell p{
        font-size: 16px;
        color: #676666;
    }
    .services_sell{
        width: 100%;
        margin-top: 1.5rem;
    }
    .talkToUs_link{
        margin-top: 1rem;
    }
    .estate_detail p{
        color: #676666;
        font-size: 14px !important;
    }
    .propertyDetail_text p{
        font-size: 14px !important;
        color: #676666;
    }
    .properyDetail_container{
        width: 87%;
        display: flex;
        flex-direction: column;
        margin-inline: auto;
    }
    .propertyImg{
        width: 100%;
        margin: -2.5rem 0 -1rem 0;
    }
    .weText {
        font-size: 23px;
        font-weight: 500;
    }
    .peace_text_detail p{
        text-align: center !important;
        color: #5A5A5A;
        font-size: 14px;
        font-weight: 100;
    }
    


}

@media screen and (max-width: 400px) {
    .home_navWrapper{
        background-image: none;
        background-repeat: no-repeat;
        background-size: cover !important;
        width: 100%;
        height: 68vh !important;
    }
}

@media screen and (max-width: 379px) {
    .home_navWrapper{
        background-image: none;
        background-repeat: no-repeat;
        background-size: cover !important;
        width: 100%;
        height: 87vh !important;
    }
}

@media screen and (max-width: 365px) {
    h2{
        font-size: 17px !important;
    }
    .home_navWrapper{
        background-image: none;
        background-repeat: no-repeat;
        background-size: cover !important;
        width: 100%;
        height: 80vh !important;
    }
    .popular_header h2{
        text-wrap: wrap !important;
    }
}

@media screen and (max-width: 345px) {
    .home_navWrapper{
        background-image: none;
        background-repeat: no-repeat;
        background-size: cover !important;
        width: 100%;
        height: 67vh !important;
    }
    h1{
        font-size: 19px !important;
    }
    h2{
        font-size: 16px !important;
    }
}